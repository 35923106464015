// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-core-values-js": () => import("./../../../src/pages/core-values.js" /* webpackChunkName: "component---src-pages-core-values-js" */),
  "component---src-pages-ecommerce-glossary-js": () => import("./../../../src/pages/ecommerce-glossary.js" /* webpackChunkName: "component---src-pages-ecommerce-glossary-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-js": () => import("./../../../src/pages/learn.js" /* webpackChunkName: "component---src-pages-learn-js" */),
  "component---src-pages-nicely-done-js": () => import("./../../../src/pages/nicely-done.js" /* webpackChunkName: "component---src-pages-nicely-done-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-service-plans-js": () => import("./../../../src/pages/service-plans.js" /* webpackChunkName: "component---src-pages-service-plans-js" */),
  "component---src-pages-services-bespoke-development-js": () => import("./../../../src/pages/services/bespoke-development.js" /* webpackChunkName: "component---src-pages-services-bespoke-development-js" */),
  "component---src-pages-services-conversion-rate-optimisation-js": () => import("./../../../src/pages/services/conversion-rate-optimisation.js" /* webpackChunkName: "component---src-pages-services-conversion-rate-optimisation-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-seo-services-js": () => import("./../../../src/pages/services/seo-services.js" /* webpackChunkName: "component---src-pages-services-seo-services-js" */),
  "component---src-pages-services-shopify-apps-js": () => import("./../../../src/pages/services/shopify-apps.js" /* webpackChunkName: "component---src-pages-services-shopify-apps-js" */),
  "component---src-pages-services-shopify-development-js": () => import("./../../../src/pages/services/shopify-development.js" /* webpackChunkName: "component---src-pages-services-shopify-development-js" */),
  "component---src-pages-services-shopify-migration-checklist-js": () => import("./../../../src/pages/services/shopify-migration/checklist.js" /* webpackChunkName: "component---src-pages-services-shopify-migration-checklist-js" */),
  "component---src-pages-services-shopify-migration-js": () => import("./../../../src/pages/services/shopify-migration.js" /* webpackChunkName: "component---src-pages-services-shopify-migration-js" */),
  "component---src-pages-services-shopify-plus-js": () => import("./../../../src/pages/services/shopify-plus.js" /* webpackChunkName: "component---src-pages-services-shopify-plus-js" */),
  "component---src-pages-services-shopify-seo-audit-js": () => import("./../../../src/pages/services/shopify-seo-audit.js" /* webpackChunkName: "component---src-pages-services-shopify-seo-audit-js" */),
  "component---src-pages-services-ui-ux-web-design-js": () => import("./../../../src/pages/services/ui-ux-web-design.js" /* webpackChunkName: "component---src-pages-services-ui-ux-web-design-js" */),
  "component---src-pages-shopify-meetup-js": () => import("./../../../src/pages/shopify-meetup.js" /* webpackChunkName: "component---src-pages-shopify-meetup-js" */),
  "component---src-pages-strategic-partnerships-js": () => import("./../../../src/pages/strategic-partnerships.js" /* webpackChunkName: "component---src-pages-strategic-partnerships-js" */),
  "component---src-pages-themes-contact-js": () => import("./../../../src/pages/themes-contact.js" /* webpackChunkName: "component---src-pages-themes-contact-js" */),
  "component---src-pages-work-as-market-js": () => import("./../../../src/pages/work/as-market.js" /* webpackChunkName: "component---src-pages-work-as-market-js" */),
  "component---src-pages-work-aspazia-js": () => import("./../../../src/pages/work/aspazia.js" /* webpackChunkName: "component---src-pages-work-aspazia-js" */),
  "component---src-pages-work-folkingtons-js": () => import("./../../../src/pages/work/folkingtons.js" /* webpackChunkName: "component---src-pages-work-folkingtons-js" */),
  "component---src-pages-work-fyne-jewellery-js": () => import("./../../../src/pages/work/fyne-jewellery.js" /* webpackChunkName: "component---src-pages-work-fyne-jewellery-js" */),
  "component---src-pages-work-hugh-maguire-js": () => import("./../../../src/pages/work/hugh-maguire.js" /* webpackChunkName: "component---src-pages-work-hugh-maguire-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-work-lovyt-js": () => import("./../../../src/pages/work/lovyt.js" /* webpackChunkName: "component---src-pages-work-lovyt-js" */),
  "component---src-pages-work-lumired-js": () => import("./../../../src/pages/work/lumired.js" /* webpackChunkName: "component---src-pages-work-lumired-js" */),
  "component---src-pages-work-peter-dobias-js": () => import("./../../../src/pages/work/peter-dobias.js" /* webpackChunkName: "component---src-pages-work-peter-dobias-js" */),
  "component---src-pages-work-savin-skin-js": () => import("./../../../src/pages/work/savin-skin.js" /* webpackChunkName: "component---src-pages-work-savin-skin-js" */),
  "component---src-pages-work-tas-solutions-js": () => import("./../../../src/pages/work/tas-solutions.js" /* webpackChunkName: "component---src-pages-work-tas-solutions-js" */),
  "component---src-pages-work-topl-cup-js": () => import("./../../../src/pages/work/topl-cup.js" /* webpackChunkName: "component---src-pages-work-topl-cup-js" */),
  "component---src-pages-work-verlin-js": () => import("./../../../src/pages/work/verlin.js" /* webpackChunkName: "component---src-pages-work-verlin-js" */),
  "component---src-templates-author-articles-js": () => import("./../../../src/templates/author-articles.js" /* webpackChunkName: "component---src-templates-author-articles-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-job-post-js": () => import("./../../../src/templates/job-post.js" /* webpackChunkName: "component---src-templates-job-post-js" */)
}

